
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
moment.locale("id");

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          nama: "",
          description: "",
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: "",
          deleteBy: "",
          deleteTime: "",
          isActive: "",
          totalCard: 0,
          totalCardSuccess: 0,
          totalCardFailed: 0,
          lastPurchase: 0,
          _id: "",
          merchantId:"",
        },
      ],
      modalEdit: false,
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      dataTemp: {
        nama: "",
        description: "",
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        isDelete: "",
        deleteBy: "",
        deleteTime: "",
        isActive: "",
        merchantId:"",
        totalCard: 0,
        totalCardSuccess: 0,
        totalCardFailed: 0,
        lastPurchase: 0,
        _id: "",
  schoolId: "",
  schoolName: ""
      },
      errorName: false,
      errorMesageName: "Nama Merchant Wajib Diisi",
      errorDesc: false,
      newName: "",
      newDesc: "",
      role: "",
      filter: false,
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      categoryId: "",
    };
  },

  beforeMount() {
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    // store.dispatch(Actions.VERIFY_AUTH);
    this.getData(this.paging.size, this.paging.page);
    this.getListKategori();
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      let search = this.name;
      let valcategoryId = "";
      if (this.categoryId) {
        valcategoryId = this.categoryId;
      }
      if (this.name) {
        search = this.name;
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_merchant/" +
          office +
          "all?" +
          "page=" +
          page +
          "&size=" +
          size +
          "&sort_by=lastPurchase&sort_dir=-1&nama=" +
          search
      )
        .then(({ data }) => {
          console.log(data, "dase");
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    filterClick() {
      this.filter = !this.filter;
    },
    getDataById(event) {
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_merchant/" + office + event._id
      )
        .then((resp) => {
          console.log(resp.data);
          this.dataTemp = resp.data;
  
          this.modalEdit = true;
        })
        .catch((e) => {
          console.log(e);
        });
      // console.log(event);
    },
    getListKategori() {
      ApiService.getWithoutSlug("crmv2/main_order/product/combo_catagory").then(
        ({ data }) => {
          this.listCategory = data;
          localStorage.setItem("listCategory", JSON.stringify(data));
        }
      );
    },

    pilihKategori(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    submitedit(event) {
      const toast = useToast();


      // console.log(this.addPurchasing);
      
      
      this.isLoading = true;
     
      var body = {
        nama: this.dataTemp.nama,
        description: this.dataTemp.description,
      };

      
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      ApiService.putWithData("crmv2/main_card/card_merchant/" + office +this.dataTemp._id, body)
        .then((resp) => {
          toast.success("Berhasil Edit Data");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
          window.location.reload();
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          this.isLoading = false;
        });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      // ApiService.delete(
      //   "crmv2/main_order/product/" + item._id
      // )
      //   .then((res) => {
      //     toast.success("Hapus Berhasil");

      //     this.getData(this.paging.size, this.paging.page);
      //     this.isLoading = false;
      //   })

      //   .catch((error) => {
      //     toast.error("Gagal Hapus");
      //     this.isLoading = false;
      //   });
    },
    getMerchant(item) {
      this.dataTemp = item;
    },

    addMerchant() {
      const toast = useToast();
      if (this.newName == "") {
        return (
          (this.errorDesc = true),
          (this.errorName = true),
          (this.errorMesageName = "Nama Merchant Wajib Diisi")
        );
      }
      if (this.newName == "" || this.newName == null) {
        return (
          (this.errorName = true),
          (this.errorDesc = false),
          (this.errorMesageName = "Nama Merchant Wajib Diisi")
        );
      }
      // if (this.newDesc == "") {
      //   return (
      //     (this.errorDesc = true),
      //     (this.errorName = false),
      //     (this.errorMesageName = "Nama Merchant Wajib Diisi")
      //   );
      // }

      if (this.newName.length < 3) {
        return (
          (this.errorName = true),
          (this.errorDesc = false),
          (this.errorMesageName = "Nama Tidak Boleh Kurang Dari 3 Karakter")
        );
      }
      var body = {
        nama: this.newName,
        description: this.newDesc,
      };

      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "/office";
      }
      ApiService.postWithData("crmv2/main_card/card_merchant" + office, body)
        .then((resp) => {
          toast.success("Berhasil Menambah Merchant");
          this.clickBatal();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.detail);
        });
    },
    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    clickBatal() {
      this.newName = "";
      this.newDesc = "";
      this.errorMesageName = "";
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("DD MMMM YYYY");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
